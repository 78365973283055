import { filter, map } from 'ramda'
import React, { useState } from 'react'
import Link from 'next/link'
import { Icon, Input, Label, Menu } from 'semantic-ui-react'
import cs from 'classnames'

import { useMenu } from '../models/api'
import { useSitemap } from '../models/url'
import { useUser } from '../models/auth'
import { makeSearchRegExp } from '../lib/regex'

const filterMenu = (menu, re) =>
  filter(
    x => !!x,
    map(item => {
      if (item.menu) {
        const submenu = filterMenu(item.menu, re)
        if (submenu.length) {
          return { ...item, menu: submenu }
        }
      } else if (item.url || item.link) {
        if (re.test(item.title)) {
          return item
        }
      }
    }, menu)
  )

export const MainNavigationMenu = ({
  isHomePage,
}: {
  isHomePage?: boolean
}) => {
  const { data: menu } = useMenu()
  return <NavigationMenu isHomePage={isHomePage} menu={menu} />
}

export const NavigationMenu = ({
  isHomePage,
  menu,
}: {
  isHomePage?: boolean
  menu: any
}) => {
  const site = useSitemap()
  const user = useUser()
  const [term, setTerm] = useState('')
  const filteredMenu = menu
    ? term.length
      ? filterMenu(menu.menu, makeSearchRegExp(term))
      : menu.menu
    : null
  return (
    <Menu
      borderless
      compact
      fluid
      text={isHomePage}
      inverted={!isHomePage}
      vertical
      style={{ overflow: 'auto' }}
    >
      <Menu.Item>
        <Input
          size="mini"
          icon="search"
          iconPosition="left"
          placeholder="Search"
          defaultValue={term}
          onChange={(e, { value }) => setTerm(value)}
        />
      </Menu.Item>
      {filteredMenu ? (
        <MenuItems
          menu={filteredMenu}
          site={site}
          collapsable={term.length === 0 && menu.menu.length > 2}
        />
      ) : null}
    </Menu>
  )
}

const MenuItems = ({ menu, site, collapsable }) => (
  <>
    {menu
      .filter(x => !!x)
      .map(x => (
        <MenuItem
          key={x.key || x.id || x.url}
          collapsable={collapsable}
          item={x}
          site={site}
        />
      ))}
  </>
)

const MenuLink = props => {
  return (
    <Link prefetch={false} {...props.linkProps} className={props.className}>
      {props.badge ? <Label color="red">{props.badge}</Label> : null}
      {props.children}
    </Link>
  )
}

const MenuItem = ({ item, site, collapsable }) => {
  if (item.url) {
    const active =
      site.isCurrentPathPrefix(item.url) || site.isCurrentPathChild(item.url)

    // Hacky convert dashboard absolute urls to relative in menu, urls are
    // populated by backend API and return absolute.
    const url = item.url.startsWith('https://dashboards')
      ? item.url.replace(/^(?:\/\/|[^/]+)*\//, '/')
      : item.url

    return (
      <>
        <Menu.Item
          as="a"
          key={item.url}
          href={url}
          className={cs({
            active,
          })}
        >
          {item.icon ? (
            <Icon
              name={item.icon}
              style={{ float: 'none', margin: '0 0.5em 0 0' }}
            />
          ) : null}
          {item.title}
        </Menu.Item>
        {item.menu && active ? (
          <Menu.Menu>
            <MenuItems menu={item.menu} site={site} collapsable={collapsable} />
          </Menu.Menu>
        ) : null}
      </>
    )
  }
  if (item.link) {
    const active = site.isCurrentPathPrefix(item.link.as, item.id === '')

    return (
      <>
        <Menu.Item
          as={MenuLink}
          key={item.id}
          linkProps={item.link}
          badge={item.badge}
          className={cs({
            active,
          })}
        >
          {item.icon ? (
            <Icon
              name={item.icon}
              style={{ float: 'none', margin: '0 0.5em 0 0' }}
            />
          ) : null}
          {item.title}
        </Menu.Item>
        {item.menu && active ? (
          <Menu.Menu>
            <MenuItems menu={item.menu} site={site} collapsable={collapsable} />
          </Menu.Menu>
        ) : null}
      </>
    )
  }
  return <MenuHeader item={item} site={site} collapsable={collapsable} />
}

const MenuHeader = ({ item, site, collapsable }) => {
  const [expanded, setExpanded] = useState(
    '1' === localStorage.getItem(item.id)
  )
  const handleSetExpanded = () => {
    const x = !expanded
    if (x) localStorage.setItem(item.id, '1')
    else localStorage.removeItem(item.id)
    setExpanded(x)
  }

  return (
    <>
      <Menu.Header
        onClick={handleSetExpanded}
        className={cs('main-menu__header', {
          collapsed: !expanded && collapsable,
        })}
      >
        {collapsable ? (
          <Icon
            style={{ float: 'right' }}
            name={expanded ? 'chevron down' : 'chevron right'}
          />
        ) : null}
        {item.icon ? (
          <Icon
            name={item.icon}
            style={{ float: 'none', margin: '0 0.5em 0 0' }}
          />
        ) : null}
        {item.title}
      </Menu.Header>
      {item.menu ? (
        <div
          className={cs('main-menu__submenu', {
            collapsed: !expanded && collapsable,
          })}
        >
          <MenuItems menu={item.menu} site={site} collapsable={collapsable} />
        </div>
      ) : null}
    </>
  )
}
